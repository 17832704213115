import { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner, useToast } from '@chakra-ui/react'
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from 'react-router-dom'
import { getLocalAuth } from '../../services/app/sessionStorage'
import { login } from '../../redux/features/authSlice'
import { addDevice as addCompanyDevice } from '../../services/api/company'
import {
	addDevice as addPartnerDevice,
	fetchPartners,
} from '../../services/api/partners'
import { initMessaging } from '../../services/firebase/messaging'
import { onMessage } from 'firebase/messaging'
import { messaging } from '../../services/firebase/config'
import SchedulesPage from '../../pages/schedules'
import { fetchOrderById } from '../../services/api/orders'
import {
	addErrand,
	addOrder,
	selectOrder,
	updateErrand,
	updateOrder,
} from '../../redux/features/orderSlice'
import PaymentsPage from '../../pages/payments'
import { fetchCategories } from '../../services/api/categories'
import { setCategories } from '../../redux/features/categoriesSlice'
import { setPartners } from '../../redux/features/partnersSlice'
import UploadProductsPage from '../../pages/upload-products'
import UploadAdditionsPage from '../../pages/upload-additions'
import { socket } from '../../config/sockets'
import PromoPage from '../../pages/promo'
import { setWaQr, setWaStatus } from '../../redux/features/whatsappSlice'
import WhatsappConfigPage from '../../pages/partner/whatsapp'
import TheRescuerURL from './rescuer'

const HomePage = lazy(() => import('../../pages/home'))
const AboutPage = lazy(() => import('../../pages/about'))
const NotFoundPage = lazy(() => import('../../pages/not-found'))
const ProductsPage = lazy(() => import('../../pages/products'))
const LoginPage = lazy(() => import('../../pages/login'))
const BusinessPage = lazy(() => import('../../pages/business'))
const ProductPage = lazy(() => import('../../pages/product'))
const ReportPage = lazy(() => import('../../pages/reports'))
const OrdersPage = lazy(() => import('../../pages/orders'))
const SupportPage = lazy(() => import('../../pages/support'))
const AdsPage = lazy(() => import('../../pages/ads'))

const ParterHomePage = lazy(() => import('../../pages/partner/home'))
const PartnerAccountPage = lazy(() => import('../../pages/partner/account'))
const PartnerCompaniesPage = lazy(() => import('../../pages/partner/companies'))
const PartnerCompaniesFormPage = lazy(() =>
	import('../../pages/partner/companies-form')
)
const PartnerPaymentsPage = lazy(() => import('../../pages/partner/payments'))
const PartnerOrdersPage = lazy(() => import('../../pages/partner/orders'))
const PartnerZonesPage = lazy(() => import('../../pages/partner/zones'))
const PartnerReportsPage = lazy(() => import('../../pages/partner/reports'))

const audio = new Audio('/assets/sounds/agus-alert.mp3')

const AppRoutes = () => {
	const { business, type } = useSelector(({ auth }) => auth)
	const { orders, errands, selectedOrder } = useSelector(({ orders }) => orders)
	const [loadingAuth, setLoadingAuth] = useState(true)
	const dispatch = useDispatch()
	const toast = useToast()

	const playAudio = () => {
		audio.play()
	}

	const _initMessaging = () => {
		let addDevice = addCompanyDevice
		if (type === 'partner') {
			addDevice = addPartnerDevice
		}
		initMessaging()
			.then((token) => {
				addDevice(business.id, token)
				navigator.serviceWorker.addEventListener('message', (event) => {
					if (event.data && event.data.command === 'playSound') {
						const audio = new Audio('/assets/sounds/agus-alert.mp3')
						audio.play()
					}
				})
				if (type === 'company') {
					onMessage(messaging, async (payload) => {
						if (payload.data.type === 'new:order') {
							const orderExists = orders.find(
								(order) => order.id === payload.data.id
							)
							if (orderExists) {
								return
							}
							playAudio()
							try {
								const { data: order } = await fetchOrderById(payload.data.id)
								dispatch(addOrder(order))
							} catch (error) {}
							toast({
								title: 'Nuevo pedido!',
								description: 'Tienes un nuevo pedido en la plataforma.',
								status: 'success',
								duration: 9000,
								isClosable: true,
							})
						}
					})
				}
			})
			.catch((error) => {
				console.error('error', error)
			})
	}

	const connectedToEvents = () => {
		console.log('Listening to events')
		setTimeout(() => {
			if (business.role === 'admin') {
				socket.emit('whatsapp:fetch')
			}
		}, 3000)
	}
	const disconnectedToEvents = () => {
		console.log('Disconnected to events')
	}

	useEffect(() => {
		return () => {
			socket.off('connect', connectedToEvents)
			socket.off('disconnect', disconnectedToEvents)
			socket.disconnect()
		}
	}, [])

	useEffect(() => {
		const auth = getLocalAuth()
		if (auth) {
			dispatch(login(auth))
			fetchCategories().then((res) => {
				dispatch(setCategories(res.data))
			})
			fetchPartners().then((res) => {
				dispatch(setPartners(res.data))
			})
		}
		setTimeout(() => {
			setLoadingAuth(false)
		}, 300)
	}, [dispatch])

	useEffect(() => {
		if (business) {
			_initMessaging()
			socket.connect()
			socket.on('connect', connectedToEvents)
			socket.on('disconnect', disconnectedToEvents)
			socket.on(`new:order:${business.id}`, (payload) => {
				const orderExists = orders.find((order) => order.id === payload.id)
				if (orderExists) {
					return
				}
				playAudio()
				dispatch(addOrder(payload))
			})
			socket.on(`update:order:${business.id}`, (payload) => {
				dispatch(updateOrder(payload))
				if (selectedOrder && selectedOrder.id === payload.id) {
					dispatch(selectOrder(payload))
				}
			})
			if (business.type === 'partner') {
				socket.on(`new:errand:${business.id}`, (payload) => {
					const errandExists = errands.find(
						(errand) => errand.id === payload.id
					)
					if (errandExists) {
						return
					}
					playAudio()
					dispatch(addErrand(payload))
				})
				socket.on(`update:errand:${business.id}`, (payload) => {
					dispatch(updateErrand(payload))
					if (selectedOrder && selectedOrder.id === payload.id) {
						dispatch(selectOrder(payload))
					}
				})
			}
			if (business.role === 'admin') {
				socket.on(`whatsapp:connection`, (payload) => {
					dispatch(setWaStatus(payload))
					if (payload.connection === 'close') {
						socket.emit('whatsapp:fetch')
					}
				})
				socket.on(`whatsapp:qr`, (payload) => {
					dispatch(setWaQr(payload))
				})
				socket.emit('whatsapp:fetch')
			}
		}
	}, [business])

	if (loadingAuth) {
		return <Spinner />
	}

	const companyRoutes = () => (
		<>
			<Route
				path='/payments'
				exact
				element={business ? <PaymentsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/ads'
				exact
				element={business ? <AdsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/orders'
				exact
				element={business ? <OrdersPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/schedules'
				exact
				element={business ? <SchedulesPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/product/:id'
				exact
				element={business ? <ProductPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/promo/:id'
				exact
				element={business ? <PromoPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/reports'
				exact
				element={business ? <ReportPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/information'
				exact
				element={business ? <BusinessPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/products/list'
				exact
				element={business ? <UploadProductsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/products/additions/list'
				exact
				element={business ? <UploadAdditionsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/products'
				exact
				element={business ? <ProductsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/home'
				exact
				element={business ? <HomePage /> : <Navigate to='/login' />}
			/>
		</>
	)

	const partnerRoutes = () => (
		<>
			<Route
				path='/zones'
				exact
				element={business ? <PartnerZonesPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/payments'
				exact
				element={business ? <PartnerPaymentsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/account'
				exact
				element={business ? <PartnerAccountPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/companies/create'
				exact
				element={
					business ? <PartnerCompaniesFormPage /> : <Navigate to='/login' />
				}
			/>
			<Route
				path='/companies'
				exact
				element={business ? <PartnerCompaniesPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/orders'
				exact
				element={business ? <PartnerOrdersPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/reports'
				exact
				element={business ? <PartnerReportsPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/whatsapp'
				exact
				element={business ? <WhatsappConfigPage /> : <Navigate to='/login' />}
			/>
			<Route
				path='/home'
				exact
				element={business ? <ParterHomePage /> : <Navigate to='/login' />}
			/>
		</>
	)

	return (
		<Router>
			<Suspense fallback={<Spinner />}>
				<Routes>
					{type === 'company' && companyRoutes()}
					{type === 'partner' && partnerRoutes()}
					<Route
						path='/support'
						exact
						element={business ? <SupportPage /> : <Navigate to='/login' />}
					/>
					<Route path='/' exact element={<Navigate to='home' />} />
					<Route path='/about' exact element={<AboutPage />} />
					<Route path='/not-found' element={<NotFoundPage />} />
					<Route path='/login' exact element={<LoginPage />} />
					<Route
						path='*'
						element={
							business ? <Navigate to='/not-found' /> : <TheRescuerURL to='/login' />
						}
					/>
				</Routes>
			</Suspense>
		</Router>
	)
}

export default AppRoutes
