import React from 'react'
import { useSelector } from 'react-redux'
import { QRCodeSVG } from 'qrcode.react'
import MainLayout from '../../components/layout/main-layout'

const WhatsappConfigPage = () => {
	const { qrCode, status } = useSelector((state) => state.whatsapp)

	return (
		<MainLayout>
			<h3 className='text-2xl text-black'>Configuración de Whatsapp</h3>
			{status === 'open' && (
				<h5>
					Estado: <span className='text-green-500'>Activo</span>
				</h5>
			)}
			{status === 'close' && (
				<h5>
					Estado: <span className='text-red-500'>Inactivo</span>
				</h5>
			)}
			{qrCode && (
				<div className='mt-4 w-full md:w-[500px]'>
					<QRCodeSVG value={qrCode} size={300} />
				</div>
			)}
		</MainLayout>
	)
}

export default WhatsappConfigPage
