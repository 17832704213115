import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { RESCUER_KEY } from '../../utils/keys'

const TheRescuerURL = ({ to }) => {
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		const pathname = location.pathname
		const params = new URLSearchParams(location.search)
		const orderValue = params.get('order')

		if (orderValue) {
			const newParams = new URLSearchParams()
			newParams.set('order', orderValue)
			const newURL = `${pathname}?${newParams.toString()}`
			sessionStorage.setItem(RESCUER_KEY, newURL)
			navigate(to)
		} else {
			navigate(to)
		}
	}, [location])

	return <>Por favor, espere un momento, los estamos redireccionando...</>
}

export default TheRescuerURL
