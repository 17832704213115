import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
	Stack,
	Input,
	Button,
	Text,
	Textarea,
	Switch,
	FormControl,
	FormLabel,
	useToast,
	Select,
	Spinner,
} from '@chakra-ui/react'
import { AddIcon, CheckIcon, ChevronLeftIcon, EditIcon } from '@chakra-ui/icons'

import MainLayout from '../components/layout/main-layout'

import { DEFAULT_VALUES } from '../utils/default'
import { appColors } from '../utils/colors'
import { createPromo, fetchPromos, updatePromo } from '../services/api/products'
import Icon from '../components/shared/icon'
import { compressAndUploadImage } from '../utils/image'
import { promoSchema } from '../schemas/promo'
import ProductSelectionModal from '../components/products/product-selection-modal'
import { setPromos } from '../redux/features/productSlice'

const PromoPage = () => {
	const [imageFile, setImageFile] = useState()
	const [openModal, setOpenModal] = useState(false)
	const [loading, setLoading] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const desktop = useSelector(({ mediaQuery }) => !mediaQuery.mobileScreen)
	const company = useSelector(({ auth }) => auth.business)
	const { promo: selectedPromo, productsMap } = useSelector(
		store => store.products
	)
	const [promo, setPromo] = useState(selectedPromo)
	const toast = useToast()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		setValue,
		control,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(promoSchema),
	})
	const watchImage = watch('image')
	const watchType = watch('type')

	useEffect(() => {
		if (promo) {
			setValue('name', promo.name)
			setValue('description', promo.description)
			setValue('value', promo.value)
			setValue('type', promo.type)
			setValue('tag', promo.tag)
			setValue('image', promo.image)
			setValue('status', promo.status === 'ACTIVE')
			setValue('applyAdditions', promo.applyAdditions)
			setValue('applyRequired', promo.applyRequired)
		}
	}, [])

	const uploadImageBeforeSaving = async () => {
		const oldImage = promo?.image ?? undefined
		return compressAndUploadImage({
			image: imageFile,
			maxWidthOrHeight: 512,
			path: `companies/${company.id}/promos`,
			old: oldImage,
		})
	}

	const onSubmit = async data => {
		if (!promo?.products || promo.products.length === 0) {
			toast({
				title: 'Error!',
				description: 'Debes seleccionar al menos un producto',
				status: 'error',
				duration: 4000,
				isClosable: true,
			})
			return
		}
		const payload = { ...promo }
		payload.name = data.name
		payload.text = data.name
		payload.description = data.description
		payload.value = data.value
		payload.type = data.type
		payload.tag = data.tag
		payload.status = data.status ? 'ACTIVE' : 'INACTIVE'
		payload.applyAdditions = data.applyAdditions
		payload.applyRequired = data.applyRequired
		if (!promo?.id) {
			payload.company = company.id
			payload.companyName = company.name
		}
		if (imageFile) {
			payload.image = await uploadImageBeforeSaving()
		}
		const promoFn = promo.id ? updatePromo : createPromo
		let title = 'Se han guardado los cambios.'
		let description = 'La información de tus productos ha sido actualizada'
		let status = 'success'
		try {
			await promoFn(payload)
		} catch (error) {
			status = 'error'
			title = 'Ha ocurrido un error!'
			description =
				'No se pudo guardar los cambios. Si el problema persiste, contacta a un administrador de Agus'
		}
		toast({
			title,
			description,
			status,
			duration: 4000,
			isClosable: true,
		})
		if (status === 'success') {
			try {
				const response = await fetchPromos(company.id)
				dispatch(setPromos(response.data))
			} catch (error) {}
			navigate('/products')
		}
	}

	const handleFileChange = evt => {
		const file = evt.target.files[0]
		const img = new Image()
		const objectUrl = URL.createObjectURL(file)
		img.onload = () => {
			setValue('image', objectUrl)
			setImageFile(file)
		}
		img.src = objectUrl
	}

	const showModal = index => {
		setSelectedIndex(index)
		setOpenModal(true)
	}

	const handleCloseModal = selection => {
		if (!selection) {
			setOpenModal(false)
			return
		}
		let newProducts = []
		if (selectedIndex >= 0) {
			newProducts = promo?.products.map((p, i) =>
				i === selectedIndex ? selection.product : p
			)
		} else {
			newProducts = [...(promo?.products ?? []), selection.product]
		}
		setPromo(JSON.parse(JSON.stringify({ ...promo, products: newProducts })))
		setOpenModal(false)
		return
	}

	return (
		<MainLayout inner={true} title='Detalle del producto'>
			{desktop && (
				<div className='flex flex-row items-center h-[30px] mb-5 ml-[-20px] mt-[-10px]'>
					<button onClick={() => navigate(-1)}>
						<ChevronLeftIcon color='black' boxSize={8} /> Productos
					</button>
				</div>
			)}
			<form onSubmit={handleSubmit(onSubmit)}>
				<Stack spacing={5}>
					<div className='flex flex-col items-center gap-2'>
						<label htmlFor='imagePicker' className='w-[170px] h-[170px]'>
							<img
								src={watchImage || DEFAULT_VALUES['square-image']}
								alt=''
								className='w-full h-full'
							/>
						</label>
						<input
							type='file'
							id='imagePicker'
							className='hidden'
							onChange={handleFileChange}
							accept='image/*'
						/>
						<Button
							onClick={() => document.getElementById('imagePicker').click()}
							variant='outline'
							boxSize={6}
						>
							<Icon icon='camera' size='sm' color='#777' />
						</Button>
					</div>
					<div className='flex flex-col gap-1'>
						<Text as='b' fontSize='12px'>
							Nombre
						</Text>
						<Input
							placeholder='Nombre'
							size='md'
							{...register('name')}
							isInvalid={errors.name?.message}
							errorBorderColor='red.300'
						/>
						{errors.name?.message && (
							<p className='text-sm text-[#f22]'>{errors.name?.message}</p>
						)}
					</div>
					<div className='flex flex-col gap-1'>
						<Text as='b' fontSize='12px'>
							Descripción
						</Text>
						<Textarea
							placeholder='Descripción'
							size='md'
							{...register('description')}
							isInvalid={errors.description?.message}
							errorBorderColor='red.300'
						/>
						{errors.description?.message && (
							<p className='text-sm text-[#f22]'>
								{errors.description?.message}
							</p>
						)}
					</div>
					<div className='flex flex-col gap-1'>
						<Text as='b' fontSize='12px'>
							Etiqueta
						</Text>
						<Input
							placeholder='Etiqueta'
							size='md'
							{...register('tag')}
							isInvalid={errors.tag?.message}
							errorBorderColor='red.300'
						/>
					</div>
					<div className='flex flex-col gap-1'>
						<Text as='b' fontSize='12px'>
							Tipo de precio
						</Text>
						<Select
							id='selectType'
							{...register('type')}
							isInvalid={errors.type?.message}
							errorBorderColor='red.300'
							w={{ base: '100%', sm: '200px' }}
						>
							<option value='AMOUNT'>Monto</option>
							<option value='PERCENT'>Porcentaje</option>
						</Select>
						{errors.type?.message && (
							<p className='text-sm text-[#f22]'>{errors.type?.message}</p>
						)}
					</div>
					<div className='flex flex-col gap-1'>
						<Text as='b' fontSize='12px'>
							Valor
						</Text>
						<Input
							placeholder='Valor'
							size='md'
							type='number'
							{...register('value')}
							isInvalid={errors.value?.message}
							errorBorderColor='red.300'
							w={{ base: '100%', sm: '200px' }}
						/>
						{errors.value?.message && (
							<p className='text-sm text-[#f22]'>{errors.value?.message}</p>
						)}
					</div>
					<div className='flex flex-col gap-1'>
						<Controller
							control={control}
							name='status'
							render={({ field: { onChange, value } }) => (
								<FormControl display='flex' alignItems='center'>
									<FormLabel htmlFor='wa-alerts' mb='0'>
										Promo activa?
									</FormLabel>
									<Switch
										id='wa-alerts'
										colorScheme='green'
										isChecked={value}
										onChange={onChange}
									/>
								</FormControl>
							)}
						/>
						<p className='text-xs text-[#555]'>
							Si esta opción está desactivada, tus clientes no verán la
							promoción en la app
						</p>
					</div>
					<div className='flex flex-col gap-1'>
						<h3 className='font-bold text-lg mb-4'>Productos</h3>
						{promo?.products?.map((product, index) => (
							<div
								key={`product-${index}`}
								className='flex flex-row items-center justify-between mb-4'
							>
								<div>
									<h3>{product.name ?? 'Sin nombre'}</h3>
									<p className='text-xs text-[#777]'>
										{product.options
											.map(op => productsMap[op].name)
											.join(', ')
											.slice(0, desktop ? 60 : 30)}
									</p>
								</div>
								<Button
									type='button'
									variant='ghost'
									colorScheme='teal'
									onClick={() => showModal(index)}
								>
									<EditIcon />
								</Button>
							</div>
						))}
						<Button
							type='button'
							variant='outline'
							colorScheme='teal'
							onClick={() => showModal(-1)}
							w={{ base: '100%', sm: '52px' }}
						>
							<AddIcon />
						</Button>
					</div>
					<div className='flex flex-col gap-1'>
						<Controller
							control={control}
							name='applyAdditions'
							render={({ field: { onChange, value } }) => (
								<FormControl display='flex' alignItems='center'>
									<FormLabel htmlFor='wa-alerts' mb='0'>
										{watchType === 'AMOUNT' ? 'Incluir' : 'Aplicar a'} adiciones
										opcionales
									</FormLabel>
									<Switch
										id='applyAdd'
										colorScheme='green'
										isChecked={value}
										onChange={onChange}
									/>
								</FormControl>
							)}
						/>
					</div>
					<div className='flex flex-col gap-1'>
						<Controller
							control={control}
							name='applyRequired'
							render={({ field: { onChange, value } }) => (
								<FormControl display='flex' alignItems='center'>
									<FormLabel htmlFor='wa-alerts' mb='0'>
										{watchType === 'AMOUNT' ? 'Incluir' : 'Aplicar a'} adiciones
										obligatorias
									</FormLabel>
									<Switch
										id='applyReq'
										colorScheme='green'
										isChecked={value}
										onChange={onChange}
									/>
								</FormControl>
							)}
						/>
					</div>
					<Button
						type='submit'
						backgroundColor={appColors.primary}
						color='#fff'
						leftIcon={<CheckIcon />}
						width={{ base: '100%', md: '200px' }}
					>
						{loading ? <Spinner /> : 'Guardar'}
					</Button>
				</Stack>
			</form>
			{openModal && (
				<ProductSelectionModal
					isOpen={openModal}
					onClose={handleCloseModal}
					multi={true}
					index={selectedIndex}
					product={selectedIndex >= 0 ? promo?.products[selectedIndex] : {}}
				/>
			)}
		</MainLayout>
	)
}

export default PromoPage
