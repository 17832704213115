import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faBars,
	faBell,
	faBullhorn,
	faCamera,
	faCalendar,
	faCaretDown,
	faCheck,
	faClose,
	faComment,
	faCubes,
	faCube,
	faDashboard,
	faDownload,
	faDriversLicense,
	faEdit,
	faExchange,
	faExternalLink,
	faEye,
	faEyeSlash,
	faHome,
	faImage,
	faPieChart,
	faReceipt,
	faRocket,
	faSearch,
	faServer,
	faShare,
	faSliders,
	faStepBackward,
	faStepForward,
	faQuestionCircle,
	faMapMarker,
	faMap,
	faShoppingBag,
	faFileExcel,
	faMessage
} from '@fortawesome/free-solid-svg-icons'

const iconsMap = {
	bars: faBars,
	home: faHome,
	'drivers-license': faDriversLicense,
	expand_more: faCaretDown,
	server: faServer,
	cubes: faCubes,
	cube: faCube,
	'pie-chart': faPieChart,
	bullhorn: faBullhorn,
	dashboard: faDashboard,
	calendar: faCalendar,
	receipt: faReceipt,
	exchange: faExchange,
	edit: faEdit,
	eye: faEye,
	'eye-slash': faEyeSlash,
	search: faSearch,
	share: faShare,
	rocket: faRocket,
	sliders: faSliders,
	image: faImage,
	download: faDownload,
	check: faCheck,
	close: faClose,
	comment: faComment,
	bell: faBell,
	'external-link': faExternalLink,
	'step-forward': faStepForward,
	'step-backward': faStepBackward,
	'question-circle': faQuestionCircle,
	'map-marker': faMapMarker,
	map: faMap,
	camera: faCamera,
	'shopping-bag': faShoppingBag,
	'file-excel': faFileExcel,
	message: faMessage
}

const Icon = ({ icon, size = 'sm', color = '#fff', className = '' }) => {
	return (
		<FontAwesomeIcon
			icon={iconsMap[icon]}
			color={color}
			size={size}
			className={className}
		/>
	)
}

export default Icon
