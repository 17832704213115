import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	status: 'close',
	qrCode: '',
}

export const whatsappSlice = createSlice({
	name: 'whatsapp',
	initialState,
	reducers: {
		setWaStatus: (state, action) => {
			if (action.payload.connection === 'open') {
				state.qrCode = ''
				state.status = 'open'
			} else if (action.payload.connection === 'close') {
				state.status = 'close'
			}
		},
		setWaQr: (state, action) => {
			if (action.payload?.length > 0) {
				state.qrCode = action.payload
			}
		},
	},
})

export const { setWaQr, setWaStatus } = whatsappSlice.actions

export default whatsappSlice.reducer
