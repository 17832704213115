import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import authReducer from './features/authSlice'
import navbarReducer from './features/navbarSlice'
import mediaQueryReducer from './features/mediaQuerySlice'
import productReducer from './features/productSlice'
import orderReducer from './features/orderSlice'
import categoriesReducer from './features/categoriesSlice'
import partnersReducer from './features/partnersSlice'
import companiesReducer from './features/companiesSlice'
import whatsappReducer from './features/whatsappSlice'

export const store = configureStore({
	devTools: true,
	reducer: {
		auth: authReducer,
		navbar: navbarReducer,
		mediaQuery: mediaQueryReducer,
		products: productReducer,
		orders: orderReducer,
		categories: categoriesReducer,
		partners: partnersReducer,
		companies: companiesReducer,
		whatsapp: whatsappReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
})

setupListeners(store.dispatch)
